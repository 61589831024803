<script setup lang="ts">
import type { CmsBlock } from '@shopware-pwa/types';
import { type LayoutConfiguration, getCmsLayoutConfiguration } from '@shopware-pwa/helpers-next';
import type { BlockCustomFields } from '~/types/models/cms/cmsBlockTypes';
import type { CSSProperties } from 'vue';

const props = defineProps<{
    content: CmsBlock;
}>();

provide('blockPosition', props.content.position as number);
provide('slotCount', props.content.slots.length as number);

const customFields: BlockCustomFields | null = (props.content.customFields as BlockCustomFields) || null;

const cmsBlockBackgroundType = customFields?.backgroundType ?? undefined;
provide('cmsBlockBackgroundType', cmsBlockBackgroundType);

const getBlockCssClasses = (cssClasses: LayoutConfiguration['cssClasses']): LayoutConfiguration['cssClasses'] => {
    const blockCssClasses = cssClasses || {};

    // add spacing classes
    if (customFields?.spacerTop === 'large') blockCssClasses['pt-12 xl:pt-20'] = true;
    if (customFields?.spacerTop === 'small') blockCssClasses['pt-9 xl:pt-12'] = true;
    if (customFields?.spacerTop === 'none') blockCssClasses['pt-0'] = true;

    if (customFields?.spacerBottom === 'large') blockCssClasses['pb-12 xl:pb-20'] = true;
    if (customFields?.spacerBottom === 'small') blockCssClasses['pb-9 xl:pb-12'] = true;
    if (customFields?.spacerBottom === 'none') blockCssClasses['pb-0'] = true;

    // add default spacing classes
    if (!customFields?.spacerBottom) blockCssClasses['pb-12 xl:pb-20'] = true;

    if (customFields?.backgroundType === 'dark') {
        blockCssClasses['text-white'] = true;
    } else if (customFields?.backgroundType === 'light') {
        blockCssClasses['text-gray-500'] = true;
    }

    return blockCssClasses;
};

const { resolvedComponent, componentName, isResolved } = resolveCmsComponent(props.content);
const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);
const blockCssClasses = getBlockCssClasses(cssClasses);

const containerStyles = {
    backgroundColor: layoutStyles.backgroundColor,
    backgroundImage: layoutStyles.backgroundImage,
} as CSSProperties;

layoutStyles.backgroundColor = undefined;
layoutStyles.backgroundImage = undefined;
layoutStyles.marginBottom = undefined;
layoutStyles.marginLeft = undefined;
layoutStyles.marginRight = undefined;
layoutStyles.marginTop = undefined;

const sectionPosition: number = inject('sectionPosition', 0);
const { block } = useBlock();
block.position = props.content.position;
block.sectionPosition = sectionPosition;
</script>

<template>
    <CmsVisibilityRestriction
        v-if="!block.hidden"
        :custom-fields="customFields"
    >
        <div v-if="!isResolved">Problem resolving component: {{ componentName }}</div>
        <div
            v-else
            :style="containerStyles"
        >
            <Component
                :is="resolvedComponent"
                :content
                :style="layoutStyles"
                :class="blockCssClasses"
            />
        </div>
    </CmsVisibilityRestriction>
</template>
